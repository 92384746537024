<template>
  <tr
    tabindex="0"
    class="focus:outline-none h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100"
  >
    <td class="pl-4">
      <p class="text-sm leading-none text-gray-800">{{ id }}</p>
      <!-- <div class="w-24 h-3 bg-gray-100 rounded-full mt-2">
        <div class="w-20 h-3 bg-green-progress rounded-full"></div>
      </div> -->
    </td>
    <td class="pl-4 cursor-pointer">
      <div class="flex items-center">
        <div class="w-10 h-10">
          <img
            class="w-full h-full"
            :src="$store.getters.get_API + '/images/' + image"
            alt="UX Design and Visual Strategy"
          />
        </div>
        <div class="pl-4">
          <p class="font-medium">
            {{ title }}
          </p>
          <p class="text-xs leading-3 text-gray-600 pt-2">
            {{ model }}
          </p>
        </div>
      </div>
    </td>
    <td class="pl-4">
      <p class="text-sm leading-none text-gray-800">{{ ean }}</p>
      <!-- <div class="w-24 h-3 bg-gray-100 rounded-full mt-2">
        <div class="w-20 h-3 bg-green-progress rounded-full"></div>
      </div> -->
    </td>
    <td class="pl-4">
      <p class="font-medium">{{ category }}</p>
      <!-- <p class="text-xs leading-3 text-gray-600 mt-2">
                5 tasks pending
              </p> -->
    </td>
    <td class="pl-4">
      <p class="text-sm leading-none text-gray-800">
        {{ spec.length > 0 ? "DA" : "NE" }}
      </p>
      <!-- <div class="w-24 h-3 bg-gray-100 rounded-full mt-2">
        <div class="w-20 h-3 bg-green-progress rounded-full"></div>
      </div> -->
    </td>

    <td class="pl-6">
      <p class="font-medium">{{ trackPrice(price) }}</p>
      <!-- <p class="text-xs leading-3 text-gray-600 mt-2">
                5 tasks pending
              </p> -->
    </td>
    <td class="pl-4">
      <p class="font-medium">
        <span
          v-if="actionPrice === 0"
          class="px-a py-2 flex justify-center items-center text-xs leading-5 font-light rounded-full uppercase bg-yellow-light text-gray-800"
        >
          Nije akcija
        </span>
        <span v-else class="text-xs leading-3 text-gray-600">
          {{
            new Intl.NumberFormat("en", { minimumFractionDigits: 2 }).format(
              actionPrice
            )
          }}
        </span>
      </p>
    </td>
    <td class="pl-4">
      <p class="font-medium">{{ promotion == 1 ? "DA" : "NE" }}</p>
      <p class="text-xs leading-3 text-gray-600 mt-2">
        {{ promotion == 1 ? "na promociji" : "nije na promociji" }}
      </p>
    </td>
    <td class="pl-4">
      <div class="flex justify-start items-center gap-2">
        <label
          ><span
            class="px-5 py-2 rounded-full" :class="status == 0 ? 'bg-green-500' : 'bg-red-500'"
            >
            {{ status == 0 ? 'Uključen' : 'Isključen' }}
          </span>
        </label>
      </div>
    </td>
    <td class="pl-4">
      <p>{{ this.postedOn(createdAt) }}</p>
      <p class="text-xs leading-3 text-gray-600 mt-2">
        {{ this.postedDate(createdAt) }}
      </p>
    </td>
    <td class="pl-4">
      <p>{{ this.postedOn(updatedAt) }}</p>
      <p class="text-xs leading-3 text-gray-600 mt-2">
        {{ this.postedDate(updatedAt) }}
      </p>
    </td>

    <td class="edit">
      <button @click="showEditModal" class="edit-btn">
        <i class="far fa-edit"></i>
      </button>
      <div
        v-if="show"
        @click="showEditModal"
        class="fixed top-0 left-0 z-30 w-full h-full bg-transparent"
      ></div>
      <div class="dropdown-content" v-if="show">
        <button @click="openModal" class="action-btn text-red-500">
          <i class="far fa-trash-alt text-base text-red-400"></i> Obrisi
        </button>
        <router-link
          :to="{ name: 'EditProduct', params: { id: id } }"
          class="action-btn text-gray-500"
          ><i class="fas fa-edit"></i> Izmeni
        </router-link>
      </div>
    </td>
    <div class="fixed inset-0 w-full h-full z-50" v-if="showModal">
      <div
        class="fixed inset-0 bg-transparent flex items-center justify-center"
      >
        <div class="w-auto mx-auto shadow-xl py-5 px-5 bg-gray-100 rounded-md">
          <div class="flex pb-3 items-center">
            <div class="text-gray-600">
              <i class="far fa-trash-alt text-xl text-red-400"></i>
            </div>
            <p class="text-lg text-gray-800 font-semibold pl-2">
              Obrisi proizvod?
            </p>
          </div>
          <p class="text-sm text-gray-600 pb-4 font-normal">
            Da li ste sigurni da zelite da obrisete proizvod?
          </p>
          <div class="flex justify-start">
            <button
              @click="deleteProduct(id)"
              class="focus:outline-none bg-red-400 transition duration-150 ease-in-out hover:bg-red-500 rounded text-white px-3 py-2 text-xs mr-5"
            >
              Obrisi Post
            </button>
            <button
              @click="openModal"
              class="focus:outline-none bg-yellow transition duration-150 ease-in-out hover:bg-yellow-light rounded text-white px-3 py-2 text-xs"
            >
              Odustani
            </button>
          </div>
        </div>
      </div>
    </div>
  </tr>
</template>

<script>
import FormPrice from "../../../middleware/PriceHandler.js";
export default {
  props: [
    "title",
    "price",
    "image",
    "id",
    "category",
    "createdAt",
    "updatedAt",
    "spec",
    "actionPrice",
    "model",
    "ean",
    "promotion",
    "status"
  ],
  data() {
    return {
      showModal: false,
      show: false,
    };
  },
  methods: {
    postedOn(time) {
      const d = new Date(time);
      const h = d.getHours();
      const m = d.getMinutes();
      const s = d.getSeconds();

      let mm = m < 10 ? "0" + m.toString() : m;
      return h + ":" + mm + ":" + s;
    },
    trackPrice(price) {
      return FormPrice(price);
    },
    postedDate(time) {
      const d = new Date(time);
      const year = d.getFullYear();
      const date = d.getDate();
      const mounths = [
        "Janudar",
        "Februar",
        "Mart",
        "April",
        "Maj",
        "Jun",
        "Jul",
        "Avgust",
        "Septembar",
        "Oktobar",
        "Novembar",
        "Decembar",
      ];
      const monthIndex = d.getMonth();
      const monthName = mounths[monthIndex];
      return date + ". " + monthName + " " + year + ".";
    },
    openModal() {
      this.showModal = !this.showModal;
    },
    showEditModal() {
      this.show = !this.show;
    },
    async deleteProduct(id) {
      let headers = this.$store.state.auth.sendHeader;
      await this.$store.dispatch("deleteProductById", { id, headers });
      await this.$store.dispatch("getPromotion");
      this.openModal();
      this.showEditModal();
    },
  },
};
</script>

<style lang="scss">
.edit {
  @apply px-0 relative;
  .edit-btn {
    @apply p-2 rounded-md focus:outline-none;
  }
  .dropdown-content {
    @apply bg-white shadow-xl border border-gray-100 w-24 absolute z-30 left-0 p-2 flex flex-col;
    .action-btn {
      @apply btn btn-lg px-2;
    }
  }
}
</style>
