<template>
  <div class="w-full mt-24">
    <the-top-bar :add="'Novi Proizvod'" :icon="'fas fa-stream'" :link="'/dilektra-cms/products/add'"
      :title="'Proizvodi'"></the-top-bar>
    <!-- overflow-y-auto  dodaj u slucaj da ne mozes da skolujes dole-->
    <div v-if="this.allProducts.length > 0" class="bg-white shadow px-9 pt-4 pb-5">
      <div class="w-full flex flex-row items-center justify-start py-4 border-b border-gray-200">
        <div class="flex items-center py-0 px-4">
          <p class="text-base text-gray-600 dark:text-gray-400" id="page-view" v-if="this.search.length == 0">
            {{ startPosition + 1 }} -
            {{
              endPosition > trackAllProducts.length
              ? trackAllProducts.length
              : endPosition
            }}
            od {{ trackAllProducts.length }}
          </p>
          <p class="text-base text-gray-600 dark:text-gray-400" id="page-view" v-if="this.search.length > 0">
            {{ startPosition + 1 }} -
            {{
              endPosition > searchProduct.length
              ? searchProduct.length
              : endPosition
            }}
            od {{ searchProduct.length }}
          </p>
          <a class="text-gray-600 dark:text-gray-400 ml-2 border-transparent border cursor-pointer rounded"
            @click="showBefore">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-left" width="20"
              height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" />
              <polyline points="15 6 9 12 15 18" />
            </svg>
          </a>
          <a class="text-gray-600 dark:text-gray-400 border-transparent border rounded focus:outline-none cursor-pointer"
            @click="showNext">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="20"
              height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" />
              <polyline points="9 6 15 12 9 18" />
            </svg>
          </a>
        </div>
        <div class="flex items-center px-4 py-2 rounded-xl mx-2 border border-gray-400">
          <i class="fas fa-search mr-4 text-gray-400"></i>
          <input type="text" v-model="search" placeholder="pretraga..." class="text-base outline-none capitalize" />
        </div>
        <div class="flex items-center pb-3 lg:pb-0 lg:px-6">
          <div class="relative z-10">
            <select v-model="selectedCategory"
              class="focus:outline-none border border-gray-300 rounded-md focus:border-gray-800 focus:shadow-outline-gray text-base form-select block w-full py-2 px-2 xl:px-3 text-gray-600 dark:text-gray-400 appearance-none bg-transparent">
              <option>Kategorije</option>
              <option v-for="(cat, i) in catSelection" :key="i">
                {{ cat }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <table class="w-full whitespace-nowrap">
        <thead class="sticky top-116 bg-white z-30">
          <tr tabindex="0" class="focus:outline-none h-16 w-full text-sm leading-none text-gray-800">
            <th class="font-normal text-left pl-4">ID</th>
            <th class="font-normal text-left pl-4">Naziv i Model</th>
            <th class="font-normal text-left pl-4">EAN</th>
            <th class="font-normal text-left pl-4">Kategorija</th>
            <th class="font-normal text-left pl-4">Specifikacija</th>
            <th class="font-normal text-left pl-6">Cena</th>
            <th class="font-normal text-left pl-4">Akcijska Cena</th>
            <th class="font-normal text-left pl-4">Istaknut/Promocija</th>
            <th class="font-normal text-left pl-4">Status</th>
            <th class="font-normal text-left pl-4">Kreiran</th>
            <th class="font-normal text-left pl-4">Editovan</th>
            <th class="font-normal text-left pl-4 text-transparent">
              Editovan
            </th>
          </tr>
        </thead>
        <tbody class="w-full" v-if="search.length == 0">
          <Product v-for="(product, index) in trackAllProducts.slice(
            this.startPosition,
            this.endPosition
          )" :key="index" :image="product.images[product.images.length - 1].url" :price="product.price"
            :title="product.title" :id="product.id" :category="product.category" :createdAt="product.createdAt"
            :updatedAt="product.updatedAt" :spec="product.specification" :actionPrice="product.actionPrice"
            :ean="product.ean" :model="product.model" :promotion="product.promotion" :status="product.status" />
        </tbody>
        <tbody class="w-full" v-if="search.length > 0">
          <Product v-for="(product, index) in searchProduct.slice(
            this.startPosition,
            this.endPosition
          )" :key="index" :image="product.images[product.images.length - 1].url" :price="product.price"
            :title="product.title" :id="product.id" :category="product.category" :createdAt="product.createdAt"
            :updatedAt="product.updatedAt" :spec="product.specification" :actionPrice="product.actionPrice"
            :ean="product.ean" :model="product.model" :promotion="product.promotion" />
        </tbody>
      </table>
    </div>
    <no-content :listLength="this.allProducts.length"
      :message="'Nemate ni jedan proizvod, molim vas dodajte.'"></no-content>
  </div>
</template>

<script>
import Product from "../partials/TheProduct.vue";
import NoContent from "../partials/NoContent.vue";
import TheTopBar from "../partials/TheTopBar.vue";
export default {
  components: {
    Product,
    NoContent,
    TheTopBar,
  },
  data() {
    return {
      allProducts: [],
      selectedCategory: "Kategorije",
      search: "",
      startPosition: 0,
      endPosition: 9,
      catSelection: [
        "Desktop računari",
        "Laptop racunari",
        "Tableti",
        "Brandname računari",
        "Kućišta",
        "Napajanja",
        "Grafičke karte",
        "Matične ploče",
        "Memorije",
        "Procesori",
        "HDD i SSD",
        "Serveri",
        "Monitori",
        "Miševi",
        "Tastature",
        "Televizori",
        "Setovi",
        "Zvučnici",
        "Optika",
        "Slušalice",
        "Mikrofoni",
        "Neprekidna napajanja ",
        "Skeneri",
        "Štampači",
        "Multifunkcijski uređaji",
        "Toneri",
        "Ruteri",
        "Svičevi",
        "Ostalo",
        "Prečišćivači vazduha",
        "Korišćena oprema",
        "Eksterni diskovi",
        "USB memorija",
        "Memorijske kartice",
      ],
    };
  },
  mounted() {
    this.getProducts();
  },
  computed: {
    trackAllProducts() {
      return this.selectedCategory === "Kategorije" && this.search === ""
        ? this.$store.getters.get_allProducts
        : this.$store.getters.get_allProducts.filter((product) =>
          product.category
            .toLowerCase()
            .includes(this.selectedCategory.toLowerCase())
        );
    },
    searchProduct() {
      return this.search === ""
        ? this.$store.getters.get_allProducts
        : this.$store.getters.get_allProducts.filter((product) => {
          return (
            product.title.toLowerCase().includes(this.search.toLowerCase()) ||
            product.model.toLowerCase().includes(this.search.toLowerCase()) ||
            product.ean.includes(this.search)
          );
        });
    },
  },
  methods: {
    async getProducts() {
      /**
       * TODO: skloni ovaj poziv jer ovo imas u storu vec samo kocis 
       * inicijalno ucitavanje
       */
      // await this.$store.dispatch("getAllProducts");
      this.allProducts = await this.$store.getters.get_allProducts;
    },
    showBefore() {
      if (this.startPosition < 1) {
        return;
      }
      this.startPosition -= 9;
      this.endPosition -= 9;
    },
    showNext() {
      if (
        this.endPosition > this.searchProduct.length ||
        this.endPosition > this.trackAllProducts.length ||
        this.endPosition == this.trackAllProducts.length ||
        this.endPosition == this.searchProduct.length
      ) {
        return;
      }
      this.startPosition = this.endPosition;
      this.endPosition += 9;
    },
  },
};
</script>

<style>
.shadow-bottom {
  box-shadow: 0px 15px 20px -25px #373438;
}
</style>

<!-- <script>
export default {
    name: "CompactTableWithActionsAndSelect",
    data() {
        return {
            temp: 0,
        };
    },
    methods: {
        dropdownFunction(event) {
            var dropdowns = document.getElementsByClassName("dropdown-content");
            var i;
            let list = event.currentTarget.parentElement.parentElement.getElementsByClassName("dropdown-content")[0];
            for (i = 0; i < dropdowns.length; i++) {
                dropdowns[i].classList.add("hidden");
            }
            list.classList.toggle("hidden");
        },
        documentClick(event) {
            if (!event.target.matches(".dropbtn")) {
                var dropdowns = document.getElementsByClassName("dropdown-content");
                var i;
                for (i = 0; i < dropdowns.length; i++) {
                    var openDropdown = dropdowns[i];
                    openDropdown.classList.add("hidden");
                }
            }
        },
        checkAll(event) {
            let rows = event.currentTarget.parentElement.parentElement.parentElement.nextElementSibling.children;
            for (var i = 0; i < rows.length; i++) {
                if (event.currentTarget.checked) {
                    rows[i].classList.add("bg-gray-100");
                    let checkbox = rows[i].getElementsByTagName("input")[0];
                    if (checkbox) {
                        checkbox.checked = true;
                    }
                } else {
                    rows[i].classList.remove("bg-gray-100");
                    let checkbox = rows[i].getElementsByTagName("input")[0];
                    if (checkbox) {
                        checkbox.checked = false;
                    }
                }
            }
        },
        tableInteract(event) {
            var single = event.currentTarget.parentElement.parentElement;
            single.classList.toggle("bg-gray-100");
        },
        pageView(val) {
            let text = document.getElementById("page-view");
            if (val) {
                if (this.$data.temp === 2) {
                    this.$data.temp = 0;
                } else {
                    this.$data.temp = this.$data.temp + 1;
                }
            } else if (this.$data.temp !== 0) {
                this.$data.temp = this.$data.temp - 1;
            }
            switch (this.$data.temp) {
                case 0:
                    text.innerHTML = "Viewing 1 - 20 of 60";
                    break;
                case 1:
                    text.innerHTML = "Viewing 21 - 40 of 60";
                    break;
                case 2:
                    text.innerHTML = "Viewing 41 - 60 of 60";
            }
        },
    },
    created() {
        document.addEventListener("click", this.documentClick);
    },
    unmounted() {
        document.removeEventListener("click", this.documentClick);
    },
};
</script> -->
